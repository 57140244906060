<template>
    <div class="Details" id="DetailsH5">
        <!-- 顶部 -->
        <img class="mapBgc" src="../assets/images/twoBgc.png" alt="">
        <div class="nav">
            <div class="navCon">
                <h2 >中国外文局亚太传播中心</h2>
                <div @click="indexUrl()" class="return">返回首页</div>
                <!-- 切换多语言 -->
                <!-- <div class="language">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            {{$t('language')}}：
                            <img v-if="tablang=='cn'" src="../assets/images/cn.png" alt="">
                            <img v-if="tablang=='uk'" src="../assets/images/uk.png" alt="">
                            <img v-if="tablang=='jp'" src="../assets/images/jp.png" alt="">
                            <img v-if="tablang=='kr'" src="../assets/images/kr.png" alt="">
                             {{tablang}}
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="cn"><img src="../assets/images/cn.png" alt=""> 简体中文(CN)</el-dropdown-item>
                            <el-dropdown-item command="uk"><img src="../assets/images/uk.png" alt=""> ENGLISH（UK）</el-dropdown-item>
                            <el-dropdown-item command="jp"><img src="../assets/images/jp.png" alt=""> にほんご（JP）</el-dropdown-item>
                            <el-dropdown-item command="kr"><img src="../assets/images/kr.png" alt=""> 한국어（KR）</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div> -->
               <!-- 更多按钮 -->
                <!-- <div class="more">
                        <el-dropdown>
                        <span class="el-dropdown-link">
                            <img src="../assets/images/more.png" alt="">
                            {{$t('More')}}
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>{{$t('More')}}</el-dropdown-item>
                            <el-dropdown-item>{{$t('More')}}</el-dropdown-item>
                            <el-dropdown-item>{{$t('More')}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div> -->
            </div>
        </div>

        <!-- 内容 -->
        <div class="details">
            <div class="logo">
                <div class="footText">{{data[footType].title}}</div>
                <img src="../assets/images/gywm@2x.jpg" alt="">
            </div>
            <div class="detailsText">
                <h5>{{data[footType].title}}</h5>
                <h6>{{data[footType].titleE}}</h6>
                <p class="p" v-html="textP(data[footType].content)"></p>
            </div>
        </div>

        <!-- H5 -->
        <div class="mobileH5">
            <div class="navH5 ">
               <!-- 更多按钮 -->
                <!-- <div class="more">
                        <el-dropdown>
                        <span class="el-dropdown-link">
                            <img src="../assets/images/more.png" alt="">
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>{{$t('More')}}</el-dropdown-item>
                            <el-dropdown-item>{{$t('More')}}</el-dropdown-item>
                            <el-dropdown-item>{{$t('More')}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div> -->
                <h5 @click="indexUrl()">返回首页</h5>
                <span class="span">{{$t('titleH5')}}</span>
                 <!-- 切换多语言 -->
                <!-- <div class="language">
                    <el-dropdown  @command="handleCommand">
                        <span class="el-dropdown-link">
                            {{tablangH5}}
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="cn"><img src="../assets/images/cn.png" alt=""> 简体中文(CN)</el-dropdown-item>
                            <el-dropdown-item command="uk"><img src="../assets/images/uk.png" alt=""> ENGLISH（UK）</el-dropdown-item>
                            <el-dropdown-item command="jp"><img src="../assets/images/jp.png" alt=""> にほんご（JP）</el-dropdown-item>
                            <el-dropdown-item command="kr"><img src="../assets/images/kr.png" alt=""> 한국어（KR）</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div> -->
                
            </div>
            <ul>
                <li>
                    <div class="li_title">
                        <h5>{{data[footType].title}}</h5>
                        <h6>{{data[footType].titleE}}</h6>
                        <img src="../assets/images/gywm@2x.jpg" alt="">
                    </div>
                    <p class="p" v-html="textP(data[footType].content)"></p>
                </li>    
            </ul>
        </div>
        <!-- 底部 -->
        
        <div class="footer" >
            <div class="footCon">
                <div>
                    <ul>
                        <li><a @click="details(1)">{{$t('foot1')}}</a></li>
                        <li><a @click="details(2)">{{$t('foot2')}}</a></li>
                        <li><a @click="details(3)">{{$t('foot3')}}</a></li>
                        <li><a @click="details(4)">{{$t('foot4')}}</a></li>
                        <li><a @click="details(5)">{{$t('foot5')}}</a></li>
                    </ul>
                </div>
                <p>
                    {{$t('footP1')}}
                </p>
                <p>
                    {{$t('footP2')}}
                </p>
                <!-- 、 :style="footStyle" -->
                <div class="p">{{$t('Fp1')}}</div>
                <div class="p">{{$t('Fp2')}}</div>
                <!-- <div class="p" :style="footStyle">{{$t('Fp3')}}</div>
                <div class="p" :style="footStyle">{{$t('Fp4')}}</div> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            tablang:'cn',
            tablangH5:'',
            footType:'1',
            data:{
                "1":{
                    title:this.$t('foot1'),
                    titleE:'About Us',
                    content:this.$t('DetailsP1'),
                },
                "2":{
                    title:this.$t('foot2'),
                    titleE:'Contact us',
                    content:this.$t('DetailsP2'),
                },
                "3":{
                    title:this.$t('foot3'),
                    titleE:'Advertising',
                    content:this.$t('DetailsP3'),
                },
                "4":{
                    title:this.$t('foot4'),
                    titleE:'Legal counsel',
                    content:this.$t('DetailsP4'),
                },
                "5":{
                    title:this.$t('foot5'),
                    titleE:'Copyright Notice',
                    content:this.$t('DetailsP5'),
                },
            },
            // H5底部隐藏样式
            footStyle:''
        }
    },
    watch: {
        '$route'(){
            if(this.$route.query.footType){
                // this.H5hide(this.$route.query.footType)
                this.footType=this.$route.query.footType
            }
        }
    },
    mounted () {
        if(this.$route.query.footType){
            // this.H5hide(this.$route.query.footType)
            this.footType=this.$route.query.footType
        }
        this.tablangH5=localStorage.getItem('lang')
        this.tablang=localStorage.getItem('lang')
    },
    methods: {
        // 法律顾问 H5底部隐藏
        // H5hide(type){
        //     if(type==4){
        //         this.footStyle="display: none;"
        //     }else{
        //         this.footStyle=""
        //     }
        // },
        handleCommand(command){
            console.log(command);
            this.$i18n.locale=command
            this.tablang=command
            this.tablangH5=command
            localStorage.setItem('lang',command)
            location.reload();
        },
        details(id){
            this.$router.push({
                path: '/Details',
                query:{
                    footType:id
                }
            })
        },
        indexUrl(){
            this.$router.push({
                path: '/'
            })
        },
        textP(e){
            let str=e;
            let arr=str.split('<p>')
            let value=''
            arr.forEach(val => {
                value+="<p>"+val+"</p>"
            });
            return value
        },
    }
}
</script>
<style lang="less">
    // 下拉框样式
    .el-dropdown-menu{
        img{
            width: 23px;
            margin-right: 16px;
        }
        .el-dropdown-menu__item{
            font-size: 14px;
            height: 46px;
            line-height: 46px;
            border-bottom: 1px solid #FAFAFA;
            box-sizing: border-box;
            border-left: 2px solid #fff;   
        }
        .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{
            color: #333;
            background-color: #fff;
            border-left: 2px solid @myTheme;   
        }
    }
    .Details{
        min-height:calc( ~ "100%" - 135px);
        
        position: relative;
        .mapBgc{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 30px;
            width: 100%;
            opacity: 0.1;
        }
        .nav{
            position: relative;
            z-index: 1;
            background-color: @myTheme;
            height: 36px;
            line-height: 36px;
            width: 100%;
            font-size: 14px;
            color: #fff;
            // 顶部样式
            .navCon{
                letter-spacing: 1px;
                color: #fff;
                h2{
                    cursor: pointer;
                    font-size: 14px;
                    color: #fff;
                    font-weight: 400;
                    float: left;
                    margin-left: 16%;
                }
                .return{
                    cursor: pointer;
                    float: right;
                    margin-right: 16%;
                }
                .el-dropdown{
                    color: #fff;
                    font-size: 14px;
                }
                .more{
                    float: right;
                    margin-right: 35px;
                    img{
                        width: 14px;
                        margin-right: 2px;
                        vertical-align: text-top;
                    }
                }
                .language{
                    float: right;
                    margin-right: 38px;
                    img{
                        width: 27px;
                    }
                    .el-dropdown{
                        padding-right: 30px;
                    }
                    .el-dropdown::after{
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 12px;
                        transform: translateY(-50%);
                        width: 0;
                        height: 0;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        border-top: 4px solid #fff;
                    }
                }
            }
        }
        // 内容
        .details{
            position: relative;
            width: 1200px;
            margin: 0 auto;
            padding: 140px 0 180px;
            .logo{
                width: 50%;
                text-align: center;
                box-sizing: border-box;
                display: inline-block;
                .footText{
                    position: absolute;
                    top: 280px;
                    width: 1000px;
                    left: -420px;
                    font-size: 106px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.04);
                    transform: rotate(270deg);
                }
                img{
                    position: absolute;
                    top: 250px;
                    left: 100px;
                    z-index: 1;
                    width: 400px;
                    vertical-align: baseline;
                }
            }
            .detailsText{
                position: relative;
                width: 50%;
                box-sizing: border-box;
                display: inline-block;
                h5{
                    font-size: 30px;
                    color: #000000;
                    margin-bottom: 22px;
                }
                h6{
                    font-weight: 400;
                    color: #999999;
                    font-size: 20px;
                }
                .p{
                    position: relative;
                    font-size: 18px;
                    color: #000000;
                    // text-indent: 2em;
                    letter-spacing: 2px;
                    height: 323px;
                    overflow: hidden;
                    overflow-y: auto;
                    margin-top: 10px;
                    padding-left: 16px;
                }
                .p::-webkit-scrollbar{
                    display: none;
                }
                
            }
            .detailsText::after{
                content: '';
                position: absolute;
                top: 110px;
                left: 0;
                width: 4px;
                height: 172px;
                background: #D70000;
            }
        }
        // 底部样式
        .footer{
            background-color: @myTheme;
            overflow: hidden;
            position: fixed;
            bottom: 0;
            width: 100%;
            z-index: 1;
            .footCon{
                width: @content;
                margin: 0 auto;
                color: #fff;
                font-size: 12px;
                text-align: center;
                ul{
                        display: inline-block;
                        margin: 15px auto 5px;
                    li{
                        float: left;
                        a{
                            cursor: pointer;
                            padding: 0px 15px;
                            color: #fff;
                            font-size: 12px;
                            border-right: 1px solid #fff;
                        }
                    }
                    li:last-child a{
                        border-right:0;
                    }
                }
                p{
                    margin-bottom: 10px;
                }
                .p{
                    display: none;
                }
            }
        }
    }
    .mobileH5{
        display: none;
    }
    // 低于750px
    @media (max-width: 750px) {
        .app{
            // .px2rem(width,750);
            min-width: auto;
        }
        .details{
            display: none;
        }
        #DetailsH5{
            min-height: 100%;
            height: 100%;
            .mapBgc{
                    display: none;
                }
            .nav{
                display: none;
            }
            .mobileH5{
                display: block;
                min-height:calc( ~ "100%" - 1.2rem);
                padding-bottom: 1.4rem;
                .navH5{
                    position: relative;
                    .px2rem(height,320);
                    display: block;
                    width: 100%;
                    background-color: @myTheme;
                    font-size: 0.3rem;
                    text-align: center;
                    box-sizing: border-box;
                    color: #fff;
                    padding-top: 0.5rem;
                    h5{
                        float: left;
                        font-weight: 400;
                        margin-left:0.44rem ;
                        margin-top: 0.04rem;
                    }
                    .more{
                        float: right;
                        margin-right: 0.3rem;
                        img{
                            width: 0.4rem;
                        }
                    }
                    .span{
                        position: absolute;
                        left: 50%;
                        top: 0.5rem;
                        transform: translateX(-50%);
                    }
                    .language{
                        float: left;
                        margin-left:0.44rem ;
                        .el-dropdown{
                            position: relative;
                            font-size: 0.3rem;
                            color: #fff;
                            padding-right: 0.4rem;
                        }
                        .el-dropdown::after{
                        content: '';
                            position: absolute;
                            top: 50%;
                            right: 0.1rem;
                            transform: translateY(-50%);
                            width: 0;
                            height: 0;
                            border-left: 0.1rem solid transparent;
                            border-right: 0.1rem solid transparent;
                            border-top: 0.1rem solid #fff;
                        }
                    }
                }
                ul{
                    position: relative;
                    top: -1.4rem;
                    width: 7rem;
                    margin: 0 auto;
                    // padding-bottom: 1.4rem;
                    li{
                        // height: 2.58rem;
                        background: #FFFFFF;
                        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                        opacity: 1;
                        border-radius: 12px;
                        margin-bottom: 0.1rem;
                        padding: 0.36rem;
                        .p{
                            text-indent: 2em;
                            font-size: 0.28;
                            color: #666;
                        }
                        .li_title{
                            position: relative;
                            margin-bottom: 0.38rem;
                            h5{
                                font-size: 0.3rem;
                                color: #030303;
                                padding-top: 0.1rem;
                            }
                            h6{
                                font-weight: 400;
                                font-size: 0.22rem;
                                color: #999;
                            }
                            img{
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 1.5rem;
                            }
                        }
                    }
                }
            }
            .footCon{
                width: 7.5rem;
                font-size: 0.2rem;
                padding-bottom: 0.2rem;
                li{
                    a{
                        font-size: 0.2rem;
                        
                        padding: 0 0.2rem;
                    }
                }
                p{
                    display: none;
                }
                .p{
                    display: block;
                    text-align: center;
                    font-size: 0.14rem;
                    // margin-bottom: 0.2rem;
                }
                
            }
            .footer{
                // position: relative;
                min-height: 1.2rem;
            }
        }
	}
     /*滑入——从顶部*/
    @keyframes map_left {
        0% {
            left: 400px;
        }
        50%{
            left: -300px;
        }
        100% {
            left: -300px;
        }
    }
    @keyframes map_left_red {
        0% {
            left: 400px;
            opacity: 0;
        }
        50%{
            left: -300px;
            opacity: 0;
        }
        100% {
            left: -300px;
            opacity: 1;
        }
    }
    @keyframes cardHide {
        0% {
            top: 320px;
        }
        75%{
            top: 320px;
        }
        100% {
            top: 0px;
        }
    }

    @keyframes blockHide {
        0% {
            opacity: 0;
        }
        50%{
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes HideBgc {
        0% {
            background-color: rgba(80, 80, 80, 0);
        }
        50%{
            background-color: rgba(80, 80, 80, 0)
        }
        100% {
            background-color: rgba(80, 80, 80, 0.66);
        }
    }
</style>